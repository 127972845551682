import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Background from "./Background";

const IncorrectAnswers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    console.log("score: ", location.state);
    setAnswer(location?.state?.answer);
  });
  return (
    <main>
      <Background></Background>
      <section className="h-screen bg-transparent">
        <div className="flex flex-col items-center justify-center h-full gap-8">
          <div className="border-2 border-yellow-500 w-96 bg-blue-800 flex flex-col">
            <div className="w-full flex items-center justify-center">
              <h1 className="text-white">Incorrect Answers</h1>
            </div>
            <div className="flex-grow">
              <table className="bg-blue-100 w-full">
                <thead className="bg-blue-400">
                  <tr>
                    <th>Words</th>
                    <th>Pinyin</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  {answer &&
                    answer.length > 0 &&
                    answer.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.question}</td>
                        <td>{item?.subQuesion}</td>
                        <td>{item?.correct}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default IncorrectAnswers;
