const Background = () => {
  return (
    <div className="-z-50 absolute inset-0">
      <img src="/img/sky.png" alt="Sky" className="absolute inset-0"></img>
      <img src="/img/sea.png" alt="Sea" className="absolute inset-0"></img>
      <img src="/img/land.png" alt="Land" className="absolute inset-0"></img>
      {/* <img src="/img/cloud.png" alt="Cloud" className="absolute inset-0"></img> */}
    </div>
  );
};

export default Background;
