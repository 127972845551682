import { useEffect, useState } from "react";
import Background from "./Background";
import axios from "axios";

const Leaderboard = () => {
  const [ranks, setRanks] = useState([]);
  const [myrank, setMyRank] = useState({});
  const getRanks = async () => {
    const username = localStorage.getItem("username");
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/ranks?username=${username}&max_score=true`;
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        setRanks(response?.data?.items);
        setMyRank(response?.data?.ranks);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getRanks();
  }, []);
  return (
    <main>
      <Background></Background>
      <section className="h-screen bg-transparent">
        <div className="flex flex-col items-center justify-center h-full gap-8">
          <div className="border-2 border-yellow-500 w-96 bg-blue-800 flex flex-col">
            <div className="w-full flex items-center justify-center">
              <h1 className="text-white">Leaderboard</h1>
            </div>
            <div className="flex-grow">
              <table className="bg-blue-100 w-full">
                <thead className="bg-blue-400">
                  <tr>
                    <th>Rank</th>
                    <th>Name</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {ranks?.length > 0 &&
                    ranks.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.username}</td>
                        <td>{item.score}</td>
                      </tr>
                    ))}
                  <tr>
                    <td>...</td>
                    <td>...</td>
                    <td>...</td>
                  </tr>
                  <tr>
                    <td>{myrank?.rank}</td>
                    <td>{myrank?.username}</td>
                    <td>{myrank?.score}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Leaderboard;
