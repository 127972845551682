import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Background from "./Background";
import GameplayGUI from "./GameplayGUI";
import axios from "axios";
// import GameBGM from "../audio/092173122-little-and-curious-fun-quirky-.mp3";
// import GameBGM from "../audio/107193728-fun-pompous-classical-kids-puz.mp3";
import GameBGM from "../audio/107537774-quirky-cherry-happy-ukelele (online-audio-converter.com).mp3";
import GameFeverBGM from "../audio/062688013-fun-orchestra (online-audio-converter.com)-[AudioTrimmer.com].mp3";
import ButtonSFX from "../audio/mixkit-game-ball-tap-2073.wav";
import ButtonCorrectSFX from "../audio/mixkit-extra-bonus-in-a-video-game-2045-[AudioTrimmer.com].wav";
import FinishSFX from "../audio/mixkit-game-experience-level-increased-2062-[AudioTrimmer.com].wav";

const GamePlay = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [questions, setQuestions] = useState([]);
  const [timer, setTimer] = useState(60);
  const [initTimer, setInitTimer] = useState(60);
  // const [answer, setAnswer] = useState([]);

  const [score, setScore] = useState(0);
  const [curQuestion, setCurQuestion] = useState(-1);
  const [combo, setCombo] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [answer, setAnswer] = useState([]);
  const [isGameStart, setIsGameStart] = useState(false);
  const [isFeverMode, setIsFeverMode] = useState(false);
  const [isGameEnd, setIsGameEnd] = useState(false);
  const feverTime = 10000; // Fever is 10 seconds
  const feverScoreMultiplier = 2;
  const wrongAnswerMinusPoint = process.env.REACT_APP_WRONG_ANSWER_MINUS_POINT;
  const wrongAnswerMinusTime =
    process.env.REACT_APP_WRONG_ANSWER_MINUS_TIME_PERCENT;

  const [qAudio, setQAudio] = useState(new Audio());
  const [buttonSFX, setButtonSFX] = useState(new Audio(ButtonSFX));
  const [buttonCorrectSFX, setButtonCorrectSFX] = useState(
    new Audio(ButtonCorrectSFX)
  );
  const [bgm, setBGM] = useState(new Audio(GameBGM));
  const [feverBGM, setFeverBGM] = useState(new Audio(GameFeverBGM));
  const [finishSFX, setFinishSFX] = useState(new Audio(FinishSFX));

  // const serverURL = "https://chenrin.erista.xyz";
  const serverURL = process.env.REACT_APP_BACKEND_API;

  function submitAnswer(ansId) {
    var isTrue = false;

    if (
      questions[curQuestion]?.options[ansId]?.option ===
      questions[curQuestion].correctQuestion
    ) {
      isTrue = true;
    }

    if (isTrue) {
      playButtonCorrectSFX();
      setCorrectAnswer(correctAnswer + 1);
      if (isFeverMode) {
        setScore(score + 100 * feverScoreMultiplier);
      } else {
        setScore(score + 100);
      }
      if (combo < 5) {
        setCombo(combo + 1);
      }
    } else {
      let newTimer =
        Number(timer) -
        Math.ceil((Number(timer) * Number(wrongAnswerMinusTime)) / 100);

      if (newTimer <= 0) {
        setTimer(0);
        timeUp();
      } else {
        setTimer(newTimer);
      }

      playButtonSFX();
      // setCombo(0); // tidak reset combo walaupun salah
      setIncorrect(incorrect + 1);
      answer.push({
        question: questions[curQuestion]?.question,
        subQuesion: questions[curQuestion]?.subQuestion,
        correct: questions[curQuestion]?.correctQuestion,
      });
    }

    nextQuestion();
    console.log("curQuestion is: ", curQuestion);
  }

  function setNewTimer() {
    setTimer((prevTimer) => prevTimer - 1);
  }

  function nextQuestion() {
    if (curQuestion < questions.length - 1) {
      // console.log("curQuestion is: ", curQuestion);
      setCurQuestion(curQuestion + 1);
    } else {
      // kalau masih ada waktu, soal ngulang lagi
      setQuestions(randomizeQuestions(questions));
      setCurQuestion(1);
      // gameFinish();
    }
  }

  function playQAudio() {
    // console.log(qAudio.src);
    if (isGameStart && qAudio.src !== serverURL + "/file-audio/null") {
      // console.log("curQuestion is: ", curQuestion);
      // console.log("audio is: ", qAudio.src);
      qAudio.play();
    }
  }

  function gameFinish() {
    // alert("Game Over");
    setIsFeverMode(false);

    finishSFX.play();

    navigate("/score", {
      state: {
        score,
        combo,
        curQuestion,
        correctAnswer,
        incorrect,
        answer,
        id,
      },
    });
  }

  function timeUp() {
    setIsGameEnd(true);
  }

  function randomizeQuestions(questions) {
    return questions.sort(() => Math.random() - 0.5);
  }

  const getQuestions = async () => {
    try {
      console.log("run fetch");
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/products/${id}`;
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        console.log("response: ", response.data);
        const questionSet = response?.data;
        const questionList = randomizeQuestions(
          response?.data?.ProductVariants
        );
        let newQuestions = questionList.map((item, index) => {
          return {
            number: index + 1,
            audio: `${process.env.REACT_APP_BACKEND_API}/file-audio/${item.variant_sound}`,
            question: item.name,
            subQuestion: item.description,
            img_src: `${process.env.REACT_APP_BACKEND_API}/file/${item.variant_image}`,
            correctQuestion: item?.correct_option,
            options: [
              {
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_1}`,
                option: item.option_1,
                isCorrect: item.option_1 === item.correct_option,
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_1}`,
              },
              {
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_2}`,
                option: item.option_2,
                isCorrect: item.option_2 === item.correct_option,
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_2}`,
              },
              {
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_3}`,
                option: item.option_3,
                isCorrect: item.option_3 === item.correct_option,
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_3}`,
              },
              {
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_4}`,
                option: item.option_4,
                isCorrect: item.option_4 === item.correct_option,
                image: `${process.env.REACT_APP_BACKEND_API}/file/${item.image_option_4}`,
              },
            ].sort(() => Math.random() - 0.5),
          };
        });

        // console.log("new question: ", newQuestions);

        setQuestions(newQuestions);
        setTimer(Number(questionSet?.duration));
        setInitTimer(Number(questionSet?.duration));
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  function startGame() {
    setIsGameStart(true);

    // Play BGM
    if (bgm.paused) {
      bgm.volume = 0.2;
      bgm.loop = true;
      bgm.play();
    }

    nextQuestion();
  }

  function playButtonSFX() {
    buttonSFX.play();
  }

  function playButtonCorrectSFX() {
    buttonCorrectSFX.play();
  }

  useEffect(() => {
    getQuestions();
  }, []);

  function loadAssets() {
    // Load question images
    questions.forEach((question) => {
      const img = new Image();
      img.src = question.img_src;
      // console.log("load image: ", question.img_src);

      question.options.forEach((option) => {
        const ans_img = new Image();
        ans_img.src = option.image;
        // console.log("load option image: ", option.image);
      });
    });

    // Load audio
    setButtonSFX(new Audio(ButtonSFX));
    setButtonCorrectSFX(new Audio(ButtonCorrectSFX));
    setBGM(new Audio(GameBGM));
    setFeverBGM(new Audio(GameFeverBGM));
    setFinishSFX(new Audio(FinishSFX));

    // Load question audio
    questions.forEach((question) => {
      const audio = new Audio(question.audio);
      // console.log("load audio: ", question.audio);
    });
  }

  useEffect(() => {
    loadAssets();
  }, [questions]);

  useEffect(() => {
    setQAudio(new Audio(questions[curQuestion]?.audio));
  }, [curQuestion]);

  useEffect(() => {
    setTimeout(function () {
      playQAudio();
    }, 500);
  }, [qAudio]);

  useEffect(() => {
    if (isGameEnd) {
      gameFinish();
    }
  }, [isGameEnd]);

  useEffect(() => {
    if (isGameStart) {
      if (isFeverMode) {
        playButtonCorrectSFX();
        stopFeverMode();
        bgm.pause();

        if (feverBGM.paused) {
          feverBGM.volume = 0.5;
          feverBGM.loop = true;
          feverBGM.play();
        }
      } else {
        feverBGM.pause();
        bgm.play();
      }
    }
  }, [isFeverMode]);

  function stopFeverMode() {
    setTimeout(function () {
      setIsFeverMode(false);
      setCombo(0);
    }, feverTime);
  }

  return (
    <main className="font-comfortaa">
      <img
        src="/img/logo (1).png"
        alt="Logo Cenrin"
        className="max-w-[60px] fixed top-0 left-0"
      ></img>
      {/* <Background></Background> */}
      <div className="hidden">
        <audio src={GameBGM}></audio>
        <audio src={GameFeverBGM}></audio>
        <audio src={ButtonCorrectSFX}></audio>
        <audio src={FinishSFX}></audio>
        {questions && questions.length > 0 ? (
          questions.map((item, index) => {
            return (
              <div key={index}>
                <img
                  id="q_img"
                  src={item?.img_src}
                  alt=""
                  className="flex-grow max-h-48 max-w-xs object-contain"
                />

                <audio src={item?.audio}></audio>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      {!isGameStart ? (
        <>
          <section className="h-screen bg-primary-200">
            <div className="flex items-center justify-center h-full">
              <button
                className="font-extrabold text-2xl border-2 border-black px-8 py-3 w-48 h-14 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
                onClick={() => {
                  startGame();
                  playButtonSFX();
                }}
              >
                Start
              </button>
            </div>
          </section>
        </>
      ) : (
        <>
          {questions && questions.length > 0 ? (
            <>
              <div>
                {isFeverMode && (
                  <div class="pyro">
                    <div class="before"></div>
                    <div class="after"></div>
                  </div>
                )}
              </div>
              <section
                className={
                  "h-screen bg-primary-200" + (isFeverMode ? " rainbow-bg" : "")
                }
              >
                <div className="flex flex-col items-center justify-evenly pb-36 h-full overflow-y-auto">
                  <div className="flex flex-col items-center justify-between gap-2">
                    <div className="relative pt-12">
                      <h1
                        id="q_title"
                        className="text-xl sm:text-3xl font-medium text-black"
                      >
                        {questions[curQuestion].question}
                      </h1>
                      <button
                        onClick={playQAudio}
                        className="text-gray-600 absolute -right-8 top-12 bottom-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
                          />
                        </svg>
                      </button>
                    </div>
                    <p
                      id="q_subtitle"
                      className="text-6xl sm:text-7xl md:text-9xl text-black text-center tracking-normal leading-tight"
                    >
                      {questions[curQuestion].subQuestion}
                    </p>
                    <img
                      id="q_img"
                      src={questions[curQuestion].img_src}
                      alt=""
                      className="flex-grow max-h-32 md:max-h-48 max-w-xs object-contain"
                    />
                  </div>

                  <ul className="grid grid-cols-2 md:grid-cols-4 gap-8 z-20 md:w-full px-8 md:gap-12 md:px-12">
                    <li
                      id="q_ans_1"
                      onClick={() => {
                        submitAnswer(0);
                      }}
                      className="relative flex flex-col items-center text-center justify-center cursor-pointer"
                    >
                      {questions[curQuestion].options[0].image !==
                        serverURL + "/file/null" && (
                        <img
                          src={questions[curQuestion].options[0].image}
                          alt="option 1"
                          className="w-full h-24 md:h-32 z-0 object-contain transition-transform hover:scale-125 duration-700"
                        />
                      )}
                      <span className="z-10 flex flex-col justify-center text-black text-sm sm:text-base md:text-lg tracking-tight leading-tight font-bold w-32 sm:w-32 md:w-full bg-primary-400 border-2 border-black rounded-xl shadow-solid-primary p-2 transition-transform hover:scale-110 duration-700 hover:bg-primary-300">
                        {questions[curQuestion].options[0].option}
                      </span>
                    </li>
                    <li
                      id="q_ans_2"
                      onClick={() => {
                        submitAnswer(1);
                      }}
                      className="relative flex flex-col items-center text-center justify-center cursor-pointer"
                    >
                      {questions[curQuestion].options[1].image !==
                        serverURL + "/file/null" && (
                        <img
                          src={questions[curQuestion].options[1].image}
                          alt="option 2"
                          className="w-full h-24 md:h-32 z-0 object-contain transition-transform hover:scale-125 duration-700"
                        />
                      )}
                      <span className="z-10 flex flex-col justify-center text-black text-sm sm:text-base md:text-lg tracking-tight leading-tight font-bold w-32 sm:w-32 md:w-full bg-primary-400 border-2 border-black rounded-xl shadow-solid-primary p-2 transition-transform hover:scale-110 duration-700 hover:bg-primary-300">
                        {questions[curQuestion].options[1].option}
                      </span>
                    </li>
                    <li
                      id="q_ans_3"
                      onClick={() => {
                        submitAnswer(2);
                      }}
                      className="relative flex flex-col items-center text-center justify-center cursor-pointer"
                    >
                      {questions[curQuestion].options[2].image !==
                        serverURL + "/file/null" && (
                        <img
                          src={questions[curQuestion].options[2].image}
                          alt="option 3"
                          className="w-full h-24 md:h-32 z-0 object-contain transition-transform hover:scale-125 duration-700"
                        />
                      )}
                      <span className="z-10 flex flex-col justify-center text-black text-sm sm:text-base md:text-lg tracking-tight leading-tight font-bold w-32 sm:w-32 md:w-full bg-primary-400 border-2 border-black rounded-xl shadow-solid-primary p-2 transition-transform hover:scale-110 duration-700 hover:bg-primary-300">
                        {questions[curQuestion].options[2].option}
                      </span>
                    </li>
                    <li
                      id="q_ans_4"
                      onClick={() => {
                        submitAnswer(3);
                      }}
                      className="relative flex flex-col items-center text-center justify-center cursor-pointer"
                    >
                      {questions[curQuestion].options[3].image !==
                        serverURL + "/file/null" && (
                        <img
                          src={questions[curQuestion].options[3].image}
                          alt="option 4"
                          className="w-full h-24 md:h-32 z-0 object-contain transition-transform hover:scale-125 duration-700"
                        />
                      )}
                      <span className="z-10 flex flex-col justify-center text-black text-sm sm:text-base md:text-lg tracking-tight leading-tight font-bold w-32 sm:w-32 md:w-full bg-primary-400 border-2 border-black rounded-xl shadow-solid-primary p-2 transition-transform hover:scale-110 duration-700 hover:bg-primary-300">
                        {questions[curQuestion].options[3].option}
                      </span>
                    </li>
                  </ul>
                </div>
              </section>
              <GameplayGUI
                score={score}
                timer={timer}
                combo={combo}
                setIsFeverMode={setIsFeverMode}
                timeUp={timeUp}
                setNewTimer={setNewTimer}
              />
            </>
          ) : (
            <div></div>
          )}
        </>
      )}
    </main>
  );
};

export default GamePlay;
