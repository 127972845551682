import { Dialog, Transition } from "@headlessui/react";
import { ArrowUturnLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import GameBGM from "../audio/107193728-fun-pompous-classical-kids-puz.mp3";
import ButtonSFX from "../audio/mixkit-game-ball-tap-2073.wav";
import GameplayBGM from "../audio/107537774-quirky-cherry-happy-ukelele (online-audio-converter.com).mp3";
import GameFeverBGM from "../audio/062688013-fun-orchestra (online-audio-converter.com)-[AudioTrimmer.com].mp3";
import ButtonCorrectSFX from "../audio/mixkit-extra-bonus-in-a-video-game-2045-[AudioTrimmer.com].wav";
import FinishSFX from "../audio/mixkit-game-experience-level-increased-2062-[AudioTrimmer.com].wav";

const Home = () => {
  const [questions, setQuestion] = useState([]);
  const [categories, setCategories] = useState([]);
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [bgm, setBGM] = useState(new Audio(GameBGM));
  const [buttonSFX, setButtonSFX] = useState(new Audio(ButtonSFX));

  function playButtonSFX() {
    buttonSFX.play();
  }

  const getCategories = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/categories?is_active=true`;
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        setCategories(response?.data);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getQuestionSet = async (category_id) => {
    console.log("category_id: ", category_id);
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/products?is_active=true&category=${category_id}`;
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        setQuestion(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  function playBGM() {
    if (bgm.paused) {
      bgm.volume = 0.5;
      bgm.loop = true;
      bgm.play();
    }
  }

  const handleInputChange = (event) => {
    setUsername(event.target.value.replace(/\s/g, ""));

    localStorage.setItem("username", event.target.value.replace(/\s/g, ""));
  };

  useEffect(() => {
    console.log("Data yang dimasukkan:", username);
  }, [username]);

  useEffect(() => {
    getCategories();

    const localUsername = localStorage.getItem("username");
    console.log("username: ", localUsername);
    if (localUsername) {
      setUsername(localUsername);
    }
  }, []);

  const [open, setOpen] = useState(false);
  const [ranks, setRanks] = useState([]);
  const [myrank, setMyRank] = useState({});
  const getRanks = async () => {
    const username = localStorage.getItem("username");
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/ranks?username=${username}&max_score=true`;
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        setRanks(response?.data?.items);
        setMyRank(response?.data?.ranks);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    getRanks();
  }, []);

  const [openCredits, setOpenCredits] = useState(false);

  const [openCategorySelect, setOpenCategorySelect] = useState(false);
  const [openLevelSelect, setOpenLevelSelect] = useState(false);

  return (
    <main className="font-comfortaa">
      <div className="hidden">
        <audio src={GameplayBGM}></audio>
        <audio src={GameFeverBGM}></audio>
        <audio src={ButtonCorrectSFX}></audio>
        <audio src={FinishSFX}></audio>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="font-comfortaa fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                  <div className="absolute right-0 top-0 pr-2 pt-2">
                    <button
                      type="button"
                      className="rounded-md bg-transparent text-black hover:text-gray-500"
                      onClick={() => {
                        setOpen(false);
                        playButtonSFX();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex flex-col items-center justify-center h-full gap-8">
                    <div className="border-2 border-black w-full min-w-[20rem] bg-primary-400 flex flex-col">
                      <div className="w-full flex items-center justify-center">
                        <h1 className="text-white font-bold px-2 py-1">
                          Global Leaderboard
                        </h1>
                      </div>
                      <div className="flex-grow">
                        <table className="bg-white w-full">
                          <thead className="bg-black text-white">
                            <tr>
                              <th className="px-2 py-1 text-xs w-8">Rank</th>
                              <th className="px-2 py-1 text-xs">Name</th>
                              <th className="px-2 py-1 text-xs text-right">
                                Score
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-sm">
                            {ranks?.length > 0 &&
                              ranks.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center px-2 py-1">
                                    {index + 1}
                                  </td>
                                  <td className="text-left px-2 py-1">
                                    {item.username}
                                  </td>
                                  <td className="text-right px-2 py-1">
                                    {item.score}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td className="text-center px-2 py-1">...</td>
                              <td className="text-left px-2 py-1">...</td>
                              <td className="text-right px-2 py-1">...</td>
                            </tr>
                            <tr>
                              <td className="text-center px-2 py-1 bg-primary-200">
                                {myrank?.rank}
                              </td>
                              <td className="text-left px-2 py-1 bg-primary-200">
                                My High Score:
                              </td>
                              <td className="text-right px-2 py-1 bg-primary-200">
                                {myrank?.score}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openCredits} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenCredits}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="font-comfortaa fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm bg-white border-4 border-black">
                  <div className="absolute right-0 top-0 pr-2 pt-1">
                    <button
                      type="button"
                      className="rounded-md bg-transparent text-black hover:text-gray-500"
                      onClick={() => {
                        setOpenCredits(false);
                        playButtonSFX();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="h-8 bg-primary-400 px-2 py-1 border-b-2 border-black">
                    <h2 className="text-white font-bold uppercase">Credits</h2>
                  </div>
                  <ul className="flex flex-col items-start justify-start h-full gap-2 p-8 text-xs list-disc">
                    <li>
                      <a href="https://www.pond5.com/royalty-free-music/item/62688013-fun-orchestra">
                        Stock Music
                      </a>{" "}
                      provided by SoundPhenomenon, from{" "}
                      <a href="https://www.pond5.com/">Pond5</a>
                    </li>
                    <li>
                      <a href="https://www.pond5.com/royalty-free-music/item/107537774-quirky-cherry-happy-ukelele">
                        Stock Music
                      </a>{" "}
                      provided by oakmusic, from{" "}
                      <a href="https://www.pond5.com/">Pond5</a>
                    </li>
                    <li>
                      <a href="https://www.pond5.com/royalty-free-music/item/92173122-little-and-curious-fun-quirky-and-playful-children-backgroun">
                        Stock Music
                      </a>{" "}
                      provided by tunestogo, from{" "}
                      <a href="https://www.pond5.com/">Pond5</a>
                    </li>
                    <li>
                      <a href="https://www.pond5.com/royalty-free-music/item/107193728-fun-pompous-classical-kids-puzzle-music-seamless-loop-no-mel">
                        Stock Music
                      </a>{" "}
                      provided by LevelUpMusicSolutions, from{" "}
                      <a href="https://www.pond5.com/">Pond5</a>
                    </li>
                    <li>
                      Website by{" "}
                      <a
                        href="https://www.instagram.com/erista.tech/"
                        className="underline"
                      >
                        PT. Erista Dwatiga Teknologi
                      </a>
                    </li>
                  </ul>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openCategorySelect} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setOpenCategorySelect}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="font-comfortaa fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-end justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all bg-primary-200 border-black w-screen h-screen overflow-y-auto flex items-center">
                  <div className="absolute left-4 bottom-4">
                    <button
                      type="button"
                      className="rounded-full bg-primary-400 p-2 border-4 border-black text-black hover:bg-primary-300"
                      onClick={() => {
                        setOpenCategorySelect(false);
                        playButtonSFX();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <ArrowUturnLeftIcon
                        className="h-8 w-8 stroke-2"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  <div className="flex flex-col max-h-full items-center w-full">
                    <div className="pt-8 pb-4 md:pb-8">
                      <h2 className="font-extrabold text-3xl text-center">
                        Grade Select
                      </h2>
                    </div>
                    <div className="flex flex-wrap gap-8 items-center justify-center flex-grow overflow-y-auto pb-20 py-4 px-12 md:px-8 max-w-7xl">
                      {categories &&
                        categories.length > 0 &&
                        categories.map((item, index) => (
                          <>
                            <div
                              key={index}
                              onClick={async () => {
                                setOpenCategorySelect(false);
                                setOpenLevelSelect(true);
                                await getQuestionSet(item.id);
                              }}
                              className="italic font-extrabold rounded-full text-2xl border-2 border-black px-8 py-3 w-full sm:w-64 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-black transition-transform hover:scale-110 duration-700"
                            >
                              {item?.name}
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openLevelSelect} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenLevelSelect}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="font-comfortaa fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-screen items-end justify-center text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all bg-primary-200 border-black w-screen h-screen overflow-y-auto flex items-center">
                  <div className="absolute left-4 bottom-4">
                    <button
                      type="button"
                      className="rounded-full bg-primary-400 p-2 border-4 border-black text-black hover:bg-primary-300"
                      onClick={() => {
                        setOpenLevelSelect(false);
                        setOpenCategorySelect(true);
                        playButtonSFX();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <ArrowUturnLeftIcon
                        className="h-8 w-8 stroke-2"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                  <div className="flex flex-col max-h-full items-center w-full">
                    <div className="pt-8 pb-4 md:pb-8">
                      <h2 className="font-extrabold text-3xl text-center">
                        Level Select
                      </h2>
                    </div>
                    {!isLoading ? (
                      <>
                        <div className="flex flex-wrap gap-8 items-center justify-center flex-grow overflow-y-auto pb-20 py-4 px-12 md:px-8 max-w-7xl">
                          {questions &&
                            questions.length > 0 &&
                            questions.map((item, index) => (
                              <>
                                <a
                                  key={index}
                                  href={`/play/${item.id}`}
                                  className="italic font-extrabold rounded-full text-2xl border-2 border-black px-8 py-3 w-full sm:w-64 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-black transition-transform hover:scale-110 duration-700"
                                >
                                  {item?.name}
                                </a>
                              </>
                            ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>Get Quiz...</div>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <section
        className="h-screen bg-primary-100 relative overflow-clip"
        onClick={playBGM}
      >
        <div className="absolute left-0 right-0 bottom-0 bg-primary-400 z-10 h-1/2">
          <div className="flex justify-between items-end h-full relative">
            <img
              className="h-96 lg:h-[32rem] object-contain w-fit absolute -bottom-28 lg:-bottom-36 -left-20 rotate-12"
              src="/img/kelinci 1.png"
              alt="Gambar Kelinci"
            ></img>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center h-full gap-4 relative z-20">
          <div className="flex flex-col items-center justify-center gap-4">
            <img
              src="/img/logo tulisan.png"
              alt="Logo Cenrin"
              className="max-w-[200px]"
            ></img>
            {/* <h2 className="font-extrabold text-2xl italic text-white bg-primary-400 px-4 py-2">
              LEVEL 1
            </h2> */}
            <h1 className="font-extrabold text-7xl px-4 py-2"></h1>
            <p className="font-semibold text-2xl"></p>
          </div>
          <div className="flex flex-col">
            {/* <label className="text-gray-400">Username: </label> */}
            <input
              type="text"
              placeholder="Input your username..."
              value={username}
              onChange={(e) => {
                handleInputChange(e);
              }}
              className="rounded-3xl border-4 border-black p-4 outline-yellow-400 text-center"
            />
          </div>
          {/* <div className="flex flex-row items-center justify-center gap-6 pt-4 w-full flex-wrap">
            {questions &&
              questions.length > 0 &&
              questions.map((item, index) => (
                <>
                  <a
                    key={index}
                    href={`/play/${item.id}`}
                    className="italic font-extrabold text-2xl border-2 border-black px-8 py-3 w-full sm:w-48 h-14 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
                  >
                    {item?.name}
                  </a>
                </>
              ))}
          </div> */}
          <button
            onClick={() => {
              setOpenCategorySelect(true);
              playButtonSFX();
            }}
            className="mt-4 font-extrabold text-2xl border-2 border-black px-8 py-3 w-64 h-14 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
          >
            Start Game
          </button>
          <button
            onClick={() => {
              setOpen(true);
              playButtonSFX();
            }}
            className="mt-4 font-extrabold text-2xl border-2 border-black px-8 py-3 w-64 h-14 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
          >
            Leaderboard
          </button>
        </div>
      </section>
      <button
        className="fixed bottom-1 right-2 text-gray-500 z-50 text-[10px] uppercase"
        onClick={() => {
          setOpenCredits(true);
          playButtonSFX();
        }}
      >
        credits
      </button>
    </main>
  );
};

export default Home;
