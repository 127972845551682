import { Fragment, useEffect, useState } from "react";
import Background from "./Background";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import StarIcon from "@heroicons/react/24/solid/StarIcon";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ButtonSFX from "../audio/mixkit-game-ball-tap-2073.wav";

const Score = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scoring, setScoring] = useState({
    score: 0,
    combo: 0,
    curQuestion: 0,
    correctAnswer: 0,
    incorrect: 0,
    answer: [],
  });
  const [status, setStatus] = useState(false);

  const [buttonSFX, setButtonSFX] = useState(new Audio(ButtonSFX));

  function playButtonSFX() {
    buttonSFX.play();
  }

  const inputScore = async () => {
    try {
      const dataState = location?.state;
      const username = localStorage.getItem("username");
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/ranks`;
      const { data: response } = await axios({
        method: "POST",
        url,
        data: {
          score: dataState?.score,
          quiz_id: dataState?.id,
          username: username,
        },
      });

      if (response && response.status == "success") {
        setStatus(true);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
    getRanks();
  };

  const [openLeaderboard, setOpenLeaderboard] = useState(false);
  const [ranks, setRanks] = useState([]);
  const [myrank, setMyRank] = useState({});

  const getRanks = async () => {
    const dataState = location?.state;
    const username = localStorage.getItem("username");
    try {
      const url = `${process.env.REACT_APP_BACKEND_API}/api/v1/ranks?username=${username}&quiz_id=${dataState?.id}&max_score=true`;
      const { data: response } = await axios({
        method: "GET",
        url,
      });

      if (response && response.status == "success") {
        setRanks(response?.data?.items);
        setMyRank(response?.data?.ranks);
      }
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const [openAnswers, setOpenAnswers] = useState(false);

  useEffect(() => {
    // console.log("score: ", location.state);
    setScoring(location?.state);

    if (Number(location?.state?.score) > 0) {
      inputScore();
    }
  }, [Number(location?.state?.score) > 0]);

  return (
    <main className="font-comfortaa">
      <img
        src="/img/logo (1).png"
        alt="Logo Cenrin"
        className="max-w-[60px] fixed top-0 left-0"
      ></img>
      <Transition.Root show={openLeaderboard} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenLeaderboard}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="font-comfortaa fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                  <div className="absolute right-0 top-0 pr-2 pt-2">
                    <button
                      type="button"
                      className="rounded-md bg-transparent text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                      onClick={() => {
                        setOpenLeaderboard(false);
                        playButtonSFX();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex flex-col items-center justify-center h-full gap-8">
                    <div className="border-2 border-black w-full min-w-[20rem] bg-primary-400 flex flex-col">
                      <div className="w-full flex items-center justify-center">
                        <h1 className="text-white font-bold px-2 py-1">
                          Leaderboard
                        </h1>
                      </div>
                      <div className="flex-grow">
                        <table className="bg-white w-full">
                          <thead className="bg-black text-white">
                            <tr>
                              <th className="px-2 py-1 text-xs w-8">Rank</th>
                              <th className="px-2 py-1 text-xs">Name</th>
                              <th className="px-2 py-1 text-xs text-right">
                                Score
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-sm">
                            {ranks?.length > 0 &&
                              ranks.map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center px-2 py-1">
                                    {index + 1}
                                  </td>
                                  <td className="text-left px-2 py-1">
                                    {item.username}
                                  </td>
                                  <td className="text-right px-2 py-1">
                                    {item.score}
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td className="text-center px-2 py-1">...</td>
                              <td className="text-left px-2 py-1">...</td>
                              <td className="text-right px-2 py-1">...</td>
                            </tr>
                            <tr>
                              <td className="text-center px-2 py-1 bg-primary-200">
                                {myrank?.rank}
                              </td>
                              <td className="text-left px-2 py-1 bg-primary-200">
                                My High Score:
                              </td>
                              <td className="text-right px-2 py-1 bg-primary-200">
                                {myrank?.score}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openAnswers} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenAnswers}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="font-comfortaa fixed inset-0 z-50 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                  <div className="absolute right-0 top-0 pr-2 pt-2">
                    <button
                      type="button"
                      className="rounded-md bg-transparent text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
                      onClick={() => {
                        setOpenAnswers(false);
                        playButtonSFX();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex flex-col items-center justify-center h-full gap-8">
                    <div className="border-2 border-black w-full min-w-[20rem] bg-primary-400 flex flex-col">
                      <div className="w-full flex items-center justify-center h-9">
                        <h1 className="text-white font-bold">
                          Incorrect Answers
                        </h1>
                      </div>
                      <div className="flex-grow">
                        <table className="bg-primary-100 w-full">
                          <thead className="bg-black text-white">
                            <tr>
                              <th className="px-2 py-1 text-xs">Words</th>
                              <th className="px-2 py-1 text-xs">Pinyin</th>
                              <th className="px-2 py-1 text-xs">Meaning</th>
                            </tr>
                          </thead>
                          <tbody>
                            {scoring?.answer &&
                              scoring?.answer.length > 0 &&
                              scoring?.answer
                                // this filter removes duplicates
                                .filter(
                                  (ele, ind) =>
                                    ind ===
                                    scoring?.answer.findIndex(
                                      (elem) => elem.question === ele.question
                                    )
                                )
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td className="px-2 py-1">
                                      {item?.question}
                                    </td>
                                    <td className="px-2 py-1">
                                      {item?.subQuesion}
                                    </td>
                                    <td className="px-2 py-1">
                                      {item?.correct}
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* <Background></Background> */}
      <section className="h-screen bg-primary-200 overflow-clip">
        <div className="absolute left-0 right-0 bottom-0 bg-primary-400 z-10 h-1/2 overflow-clip">
          <div className="flex justify-between items-end h-full">
            <img
              className="w-full h-32 sm:w-full sm:h-48 lg:h-56 object-contain"
              src="/img/kelinci 1.png"
              alt="Gambar Kelinci"
            ></img>
            <img
              className="w-full h-32 sm:w-full sm:h-48 lg:h-56 object-contain"
              src="/img/kelinci 2.png"
              alt="Gambar Kelinci"
            ></img>
            <img
              className="w-full h-32 sm:w-full sm:h-48 lg:h-56 object-contain"
              src="/img/kelinci 3.png"
              alt="Gambar Kelinci"
            ></img>
            <img
              className="w-full h-32 sm:w-full sm:h-48 lg:h-56 object-contain"
              src="/img/kelinci 4.png"
              alt="Gambar Kelinci"
            ></img>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center h-full px-8 z-20">
          <div className="bg-primary-400 border-4 border-black px-4 py-2 hidden">
            <h1>Congratulations</h1>
          </div>
          <div className="flex flex-row pr-4">
            <div className="relative h-20 w-12">
              <StarIcon className="h-20 w-20 text-black absolute inset-0 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"></StarIcon>
              {scoring?.score > 100 && (
                <>
                  <StarIcon className="h-12 w-12 text-yellow-200 absolute inset-4 animate-ping"></StarIcon>
                  <StarIcon className="h-16 w-16 text-yellow-400 absolute inset-1.5"></StarIcon>
                </>
              )}
            </div>
            <div className="relative h-20 w-16 z-20 -mt-3">
              <StarIcon className="h-24 w-24 text-black absolute inset-0 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"></StarIcon>
              {scoring?.score > 500 && (
                <>
                  <StarIcon className="h-14 w-14 text-yellow-200 absolute inset-5 animate-ping"></StarIcon>
                  <StarIcon className="h-20 w-20 text-yellow-400 absolute inset-1.5"></StarIcon>
                </>
              )}
            </div>
            <div className="relative h-20 w-12">
              <StarIcon className="h-20 w-20 text-black absolute inset-0 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"></StarIcon>
              {scoring?.score > 700 && (
                <>
                  <StarIcon className="h-12 w-12 text-yellow-200 absolute inset-4 animate-ping"></StarIcon>
                  <StarIcon className="h-16 w-16 text-yellow-400 absolute inset-1.5"></StarIcon>
                </>
              )}
            </div>
          </div>
          <div className="rounded-3xl border-4 border-black max-w-xs w-full p-4 bg-white text-black shadow-2xl z-20">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <div className="bg-primary-200/50 rounded-md px-2 py-1 grid grid-cols-2 items-center divide-x divide-white/30">
                  <span className="uppercase text-xs font-semibold text-black">
                    Correct
                  </span>
                  <span className="text-xl text-black font-semibold text-center">
                    {scoring?.correctAnswer}
                  </span>
                </div>
                <div className="bg-primary-200/50 rounded-md px-2 py-1 grid grid-cols-2 items-center divide-x divide-white/30">
                  <span className="uppercase text-xs font-semibold text-black">
                    Incorrect
                  </span>
                  <span className="text-xl text-black font-semibold text-center">
                    {scoring?.incorrect}
                  </span>
                </div>
                {/* <div className="bg-primary-200/50 rounded-md px-2 py-1 grid grid-cols-2 items-center divide-x divide-white/30">
                  <span className="uppercase text-xs font-semibold text-black">
                    Combo
                  </span>
                  <span className="text-xl text-black font-semibold text-center">
                    {scoring?.combo}{" "}
                    <span className="text-xs text-black font-semibold text-center">
                      points
                    </span>
                  </span>
                </div> */}
              </div>
              <div className="grid grid-cols-2 rounded-lg text-black">
                <div className="col-span-2 text-center flex items-center justify-around">
                  <span className="font-bold text-black text-xl">
                    Your Score
                  </span>
                </div>
                {/* <div className="flex flex-col justify-center">
                  <span className="text-xs font-medium text-white text-center">
                    Ranking
                  </span>
                  <span className="text-xl text-yellow-300 font-semibold text-center">
                    99
                  </span>
                </div> */}
                <div className="col-span-2 flex flex-row justify-center items-end">
                  <span className="text-6xl text-yellow-300 font-semibold text-center drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                    {scoring?.score}
                  </span>
                  <span className="text-xs font-medium text-black text-center">
                    points
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 mt-4">
              <div className="col-span-4 flex items-center">
                <button
                  // href="/incorrect_answers"
                  // onClick={() => {
                  //   navigate("/incorrect_answers", {
                  //     state: {
                  //       answer: scoring?.answer,
                  //     },
                  //   });
                  // }}
                  onClick={() => {
                    setOpenAnswers(true);
                    playButtonSFX();
                  }}
                  className="font-extrabold text-sm border-2 border-black w-full py-1 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
                >
                  Incorrect Answers
                </button>
              </div>
              <button
                onClick={() => {
                  setOpenLeaderboard(true);
                  playButtonSFX();
                }}
                className="col-span-2 font-extrabold text-sm border-2 border-black w-full py-1 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
              >
                Ranking
              </button>
              <a
                href="/"
                onClick={() => playButtonSFX()}
                className="col-span-2 font-extrabold text-sm border-2 border-black w-full py-1 bg-primary-400 text-center hover:bg-primary-300 shadow-solid-primary text-white transition-transform hover:scale-110 duration-700"
              >
                Main Menu
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Score;
