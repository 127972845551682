import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ComboCounterImg from "../img/erik-chov-carrot.png";
import StarIcon from "@heroicons/react/24/solid/StarIcon";

const GameplayGUI = (props) => {
  const [timer, setTimer] = useState(props?.timer);
  const minComboFever = 5;

  useEffect(() => {
    if (props?.timer) {
      setTimer(props?.timer);
    }

    const interval = setInterval(() => {
      props?.setNewTimer();

      if (props?.timer < 0) {
        clearInterval(interval);
        setTimer("EXPIRED");
        props?.timeUp();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [props?.timer, props?.timeUp]);

  function startFeverMode() {
    props?.setIsFeverMode(true);
  }

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 h-20 bg-primary-400 border-t-2 border-black grid grid-cols-3 gap-4">
        <div className="flex items-center flex-col justify-center rounded-sm">
          <h2 className="text-white uppercase font-bold text-xs">Score</h2>
          <span
            id="score"
            className="text-white font-extrabold text-3xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"
          >
            {props?.score}
          </span>
        </div>
        <div className="flex items-center flex-col justify-center rounded-sm">
          <h2 className="text-white uppercase font-bold text-xs">Time</h2>
          <span
            id="timer"
            className="text-white font-extrabold text-3xl drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]"
          >
            {timer}
          </span>
        </div>
        <div className="flex items-center flex-col rounded-sm">
          {/* <h2 className="text-white uppercase font-bold text-xs">Combo</h2> */}
          {/* <span id="combo" className="text-white font-extrabold text-3xl">
            {props?.combo}
          </span> */}
          <div
            className={
              (props?.combo > minComboFever - 1 ? "animate-bounce" : "") +
              " relative h-20 w-40 flex items-center justify-center ml-12"
            }
            onClick={() => {
              if (props?.combo > minComboFever - 1) {
                startFeverMode();
              }
            }}
          >
            <StarIcon
              className={
                (props?.combo > minComboFever - 1 ? "animate-ping" : "hidden") +
                " absolute left-6 top-0 h-16 w-16 text-white rounded-full"
              }
            ></StarIcon>
            <img
              className="absolute top-3 h-20 object-cover"
              src={ComboCounterImg}
              alt="Combo Counter Carrot"
            />
            <div
              className={
                (props?.combo > 0 ? "bg-primary-400" : "bg-black/50") +
                " absolute left-3 bottom-5 h-4 w-3 border-2 border-black"
              }
            ></div>

            <div
              className={
                (props?.combo > 1 ? "bg-primary-400" : "bg-black/50") +
                " absolute left-[25px] bottom-5 h-5 w-3 border-2 border-black"
              }
            ></div>
            <div
              className={
                (props?.combo > 2 ? "bg-primary-400" : "bg-black/50") +
                " absolute left-[38px] bottom-5 h-6 w-3 border-2 border-black"
              }
            ></div>
            <div
              className={
                (props?.combo > 3 ? "bg-primary-400" : "bg-black/50") +
                " absolute left-[51px] bottom-5 h-7 w-3 border-2 border-black"
              }
            ></div>
            <div
              className={
                (props?.combo > 4 ? "bg-primary-400" : "bg-black/50") +
                " absolute left-[64px] bottom-5 h-8 w-4 border-2 border-black"
              }
            ></div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
};

export default GameplayGUI;
